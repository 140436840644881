var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "suspended-account",
      attrs: {
        id: "suspended-account",
        "no-close-on-backdrop": true,
        centered: ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function() {
            return [
              _c("h3", [
                _vm._v(_vm._s(_vm.$t("modals.suspendedAccount.title")))
              ])
            ]
          },
          proxy: true
        },
        {
          key: "modal-footer",
          fn: function() {
            return [
              _c(
                "b-button",
                {
                  staticClass: "p-3 border-right-light-grey",
                  attrs: { block: "" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/webmag-logout")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.logout")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "p-5" }, [
        _c("div", { staticClass: "text-center" }, [
          _c("h4", { staticClass: "mb-5" }, [
            _c("strong", {
              domProps: {
                innerHTML: _vm._s(_vm.$t("modals.suspendedAccount.desc"))
              }
            })
          ]),
          !_vm.onlyOneGroupForUserExists
            ? _c(
                "div",
                [
                  _c("h5", [_vm._v("Gruppe wechseln")]),
                  _c("header-group-dropdown", {
                    attrs: {
                      "is-sys-admin": _vm.isSysAdmin,
                      "user-id": _vm.userId,
                      "group-id": _vm.groupId
                    },
                    on: {
                      "only-one-group-exists": function($event) {
                        _vm.onlyOneGroupForUserExists = true
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }