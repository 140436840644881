<template>
  <b-modal
    id="suspended-account"
    ref="suspended-account"
    :no-close-on-backdrop="true"
    centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.suspendedAccount.title') }}</h3>
    </template>
    <div class="p-5">
      <div class="text-center">
        <h4 class="mb-5">
          <strong v-html="$t('modals.suspendedAccount.desc')"></strong>
        </h4>
        <div v-if="!onlyOneGroupForUserExists">
          <h5>Gruppe wechseln</h5>
          <header-group-dropdown
            :is-sys-admin="isSysAdmin"
            :user-id="userId"
            :group-id="groupId"
            @only-one-group-exists="onlyOneGroupForUserExists = true"
          />
        </div>
      </div>
    </div>
    <template #modal-footer>
      <b-button
        class="p-3 border-right-light-grey"
        block
        @click="$router.push('/webmag-logout')"
      >
        {{ $t('buttons.logout') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalSuspendedAccount',
  components: {
    HeaderGroupDropdown: () => import('@/components/header/Buttons/HeaderGroupDropdown.vue'),
  },
  props: {
    userId: { type: Number },
    isSysAdmin: { type: Boolean },
    groupId: { type: Number },
  },
  data: () => ({
    onlyOneGroupForUserExists: false,
  }),
  mounted() {
    this.$refs['suspended-account'].show();
  },
  methods: {
    onlyOneGroupExistsForUser() {
      this.onlyOneGroupForUserExists = true;
    },
  },
};
</script>

<style scoped>
/deep/ #suspended-account {
  .group-switcher-wrapper {
    border: 1px solid $webmag-grey;
    background: #fff;
    justify-content: center;
  }
  .vue-treeselect__list,
  .vue-treeselect__menu {
    border: none;
    border-radius: 0;
    background: #fff;
  }
  .vue-treeselect--single .vue-treeselect__option--selected {
    color: $webmag-grey;
    background: $bg-header-dark;
  }
  .vue-treeselect__menu-container {
    background: $webmag-grey;
  }
  .vue-treeselect__single-value, .vue-treeselect__option--selected {
    color: $webmag-light-grey;
    background: #fff;
  }
}
</style>
